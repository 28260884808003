import { FormEvent, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';

import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';
import type { BookDemoType } from 'libs/http/api/dashboard/dashboard.types';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { external } from 'libs/http/api/external/external';
import { $Object } from 'libs/object/object.types';
import { extractQueryValue } from 'libs/node';
import { isNotBusinessEmail } from 'libs/string';

import { LeadContext } from 'context/LeadContext';
import { ContentContext } from 'context/ContentContext';

import { useProducts } from 'hooks/useProducts';
import { useRequest } from 'hooks/useRequest';

import { Input } from 'ui/atoms/Input/Input';
import { Button } from 'ui/atoms/Button/Button';
import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';

import styles from './FormRequest.module.scss';

interface Props {
  type: BookDemoType;
  title?: string;
  product?: string;
  hasBackground?: boolean;
}

export const FormRequest = ({ type, title = '', product, hasBackground = true }: Props) => {
  const { query, push } = useRouter();
  const { request: requestTrial, errors: errorsTrial, loading: loadingTrials } = useRequest<{ token: string }>();
  const { request: requestBook, errors: errorsBook, loading: loadingBook } = useRequest();
  const { data: dataProducts, loading: loadingProducts } = useProducts(() => {
    return push(secondaryNavigation.pricing.slug, { lang: `${query.lang}` });
  });

  const errors = useMemo(() => ({ ...errorsTrial, ...errorsBook }), [errorsTrial, errorsBook]);
  const loading = useMemo(() => loadingTrials || loadingBook, [loadingTrials, loadingBook]);

  const productId = useRef(
    (() => {
      const value = query.product_id || query.productId;

      if (value === undefined || typeof value === 'string') {
        return value;
      }

      return value[0];
    })(),
  ).current;

  const linkTrial = useMemo(
    () =>
      dataProducts.find((product) => `${extractQueryValue(product.link_trial, 'product_id')}` === `${productId}`)
        ?.link_trial,
    [dataProducts],
  );

  const { secondaryNavigation, data: content, pageVars } = useContext(ContentContext);
  const { leadData, setLeadData } = useContext(LeadContext);

  const [changedEmail, setChangedEmail] = useState(false);

  useEffect(() => {
    if (!changedEmail && query.email && typeof query.email === 'string') {
      setLeadData({ ...leadData, email: query.email });
      setChangedEmail(true);
    }
  }, [changedEmail, query]);

  const onChange = useCallback((target: string) => (value: string) => setLeadData({ [target]: value }), []);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const success = (route: 'thank_you' | 'thank_you_trial') => {
    if (secondaryNavigation[route]?.slug) {
      const pushQuery = {} as $Object<any>;

      if (product) {
        pushQuery.type = product;
      }

      if (query.request_sample) {
        pushQuery.request_sample = query.request_sample;
      }

      push(secondaryNavigation[route].slug, { lang: `${query.lang}` }, pushQuery);
    }
  };

  const onSubmitTrial = async () => {
    if (productId !== undefined) {
      const trialRequest = (countryId?: number) => {
        const [firstName, lastName] = leadData.full_name.split(' ');

        return requestTrial(
          dashboard.trial({
            email: leadData.email,
            phone: leadData.phone,
            first_name: firstName,
            last_name: lastName,
            product_id: productId as string,
            country_id: countryId,
          }),
        );
      };

      const checkForToken = ({ token }: { token: string }) => {
        if (token && linkTrial) {
          const origin = typeof query.origin === 'string' ? query.origin : undefined;

          /**
           * * Currently not used but once business decide to bring it back
           * * this should be rewritter with JWT and setCookies
           */
          document.cookie = `jwt-access=${token}; domain=globaldatabase.com; path=/`;
          window.location.assign(
            `${linkTrial}${origin ? `${linkTrial.includes('?') ? '&' : '?'}origin=${encodeURIComponent(origin)}` : ''}`,
          );
        } else {
          success('thank_you_trial');
        }
      };

      try {
        const { data: IPObj } = await external.getIp();
        const { data: countries } = await dashboard.nomenclatures({ lang: `${query.lang}` });
        const countryId = countries.find((country) => country.country_code === IPObj.country_code2.toUpperCase())?.id;

        const trialData = await trialRequest(countryId || undefined);

        return checkForToken(trialData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        const trialData = await trialRequest();

        return checkForToken(trialData);
      }
    }
  };

  const onSubmitBook = async () => {
    await requestBook(
      dashboard.book({
        type,
        full_name: leadData.full_name,
        email: leadData.email,
        phone: leadData.phone,
        lang: `${query.lang}`,
        additional_data: {
          product: type === 'quote' ? product : undefined,
        },
      }),
    );

    return success('thank_you');
  };

  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (productId !== undefined) {
      onSubmitTrial();
    } else {
      onSubmitBook();
    }
  };

  const fullNameError = useMemo(() => {
    if ('last_name' in errors) {
      return errors.last_name[0].replace('This field', 'Last name');
    }

    return errors.full_name || errors.first_name;
  }, [errors]);

  return (
    <div className={`${styles['form-request']} ${hasBackground && styles['form-request-bg']}`}>
      <form onSubmit={onSubmit}>
        {title && <h3>{title}</h3>}

        <Input
          type="primary"
          ariaLabel={commonPageVars.full_name}
          text={commonPageVars.full_name}
          value={leadData.full_name}
          name="full_name"
          onChange={onChange('full_name')}
          error={fullNameError}
        />

        <Input
          type="primary"
          ariaLabel={commonPageVars.work_email}
          text={commonPageVars.work_email}
          value={leadData.email}
          name="email"
          onChange={onChange('email')}
          error={isNotBusinessEmail(leadData.email) ? commonPageVars.business_email_validation : errors.email}
        />

        <Input
          type="primary"
          ariaLabel={commonPageVars.phone_label}
          text={commonPageVars.phone_label}
          value={leadData.phone}
          name="phone"
          onChange={onChange('phone')}
          error={errors.phone}
        />

        {errors.detail && <ErrorMessage error={errors.detail} position="right" />}

        <Button htmlType="submit" loading={loading || loadingProducts}>
          {content.button_text || 'Confirm'}
        </Button>

        {[1, 2, 3, 4].map((i) => commonPageVars[`book_demo_submitting_${i}`]).filter((i) => i).length > 0 && (
          <div className={styles['sub-button-text']}>
            {commonPageVars.book_demo_submitting_1}{' '}
            <Link tabIndex={0} route={secondaryNavigation.policy.slug}>
              {commonPageVars.book_demo_submitting_2}
            </Link>{' '}
            {commonPageVars.book_demo_submitting_3}{' '}
            <Link tabIndex={0} route={secondaryNavigation.terms.slug}>
              {commonPageVars.book_demo_submitting_4}
            </Link>
          </div>
        )}
      </form>
    </div>
  );
};
