import { Fragment, FormEvent, useCallback, useEffect, useMemo, useRef, useContext } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { CompanyList, Country, State, Report } from 'libs/http/api/dashboard/dashboard.types';
import { capitalize } from 'libs/string';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';
import { useStateHandlers } from 'hooks/useStateHandlers';

import { Icon } from 'ui/atoms/Icon/Icon';
import { InfinityLoader } from 'ui/atoms/InfinityLoader/InfinityLoader';
import { EmptyBlock } from 'ui/atoms/EmptyBlock/EmptyBlock';
import { CreditReportForm } from 'ui/molecules/CreditReportForm/CreditReportForm';

import { BuyReportButton } from 'features/products/organisms/BuyReportButton/BuyReportButton';

import styles from './CreditReportResults.module.scss';

export const CreditReportResults = () => {
  const { query, push } = useRouter();
  const {
    request: requestCountries,
    data: dataCountries,
    loading: loadingCountries,
  } = useRequest<Country[]>({ data: [] });
  const { request: requestStates, data: dataStates, loading: loadingStates } = useRequest<State[]>({ data: [] });
  const {
    request: requestCredits,
    errors: errorsCredits,
    loading: loadingCredits,
    data: dataCredits,
  } = useRequest<CompanyList>({ data: {} as CompanyList });
  const { pageVars, secondaryNavigation } = useContext(ContentContext);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useStateHandlers({
    error: false,
    data: [] as Report[],

    company: '',
    country: '',
    city: '',
    countryState: '',

    /** Do not support pagination. This logic should be removed in next major release. */
    page: null as number | null,
  });

  const countryOptions = useMemo(
    () =>
      Array.isArray(dataCountries)
        ? dataCountries.map((item) => ({
            value: item.short_name,
            title: item.name,
            countryId: item.id,
            research: item.research,
            freshInvestigation: item.fresh_investigation,
          }))
        : [],
    [dataCountries],
  );

  const selectedCountry = useMemo(
    () => countryOptions.find((item) => item.value === state.country),
    [countryOptions, state.country],
  );

  useEffect(() => {
    requestCountries(dashboard.country({ lang: `${query.lang}` }));
  }, []);

  useEffect(() => {
    if (hasStates) {
      requestStates(dashboard.state({ lang: `${query.lang}`, country: state.country }));

      onChangeState('countryState')(undefined as unknown as string);
    }
  }, [state.country]);

  useEffect(() => {
    if (selectedCountry?.research) {
      push(secondaryNavigation.fresh_investigation.slug, { lang: `${query.lang}` });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!state.company && typeof query.company === 'string') {
      setState({ company: query.company });
    }

    if (!state.country && typeof query.country === 'string') {
      const newCountry = dataCountries.reduce<string>((acc, current) => {
        if (query.country === current.short_name) {
          acc = current.short_name;
        }

        return acc;
      }, '');

      if (newCountry) {
        // change page to call useEffect
        setState({ country: newCountry, page: 1 });
      }
    }
  }, [query.company, query.country, dataCountries]);

  useEffect(() => {
    if (!state.error) {
      onFetch();
    }
  }, [state.page, state.error]);

  useEffect(() => {
    if (dataCredits.data) {
      setState((prevState) => ({
        data: prevState.page !== 1 ? [...prevState.data, ...dataCredits.data] : dataCredits.data,
      }));
    }
  }, [dataCredits]);

  useEffect(() => {
    if (errorsCredits.detail) {
      setState({ error: true });
    }
  }, [errorsCredits.detail]);

  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    setState((prevState) => {
      if (prevState.page === 1) {
        onFetch();
      }

      return { page: 1, error: false, data: [] };
    });
  };

  const onFetch = async () => {
    if (!state.country) {
      return;
    }

    if (!hasStates) {
      await requestCredits(
        dashboard.results({
          lang: `${query.lang}`,
          country: state.country,
          company: state.company,
          page: state.page || 1,
        }),
      );
    } else {
      await requestCredits(
        dashboard.advanced({
          lang: `${query.lang}`,
          country: state.country,
          province: state.countryState || '',
          registered_city: state.city || '',
          company: state.company,
          page: state.page || 1,
        }),
      );
    }
  };

  const onChangeState = useCallback(
    <T extends keyof typeof state>(target: string) =>
      (value: (typeof state)[T]) =>
        setState({ [target]: value }),
    [],
  );

  const hasStates = useMemo(() => ['US', 'CA'].includes(state.country), [state.country]);

  const stateOptions = useMemo(
    () => Object.entries(dataStates).map(([key, val]) => ({ code: key, title: val, value: key })),
    [dataStates],
  );

  const creditReportPageVars = useMemo(() => pageVars.credit_report || {}, [pageVars.credit_report]);

  return (
    <>
      <div className={styles['credit-block']} ref={wrapperRef}>
        <CreditReportForm
          countryOptions={countryOptions}
          countryLoading={loadingCountries}
          stateOptions={stateOptions}
          errorsCredits={errorsCredits}
          onSubmit={onSubmit}
          onChangeState={onChangeState}
          loadingStates={loadingStates}
          hasStates={hasStates}
          formData={{
            country: state.country,
            company: state.company,
            city: state.city,
            countryState: state.countryState,
            alarmCountry: !state.country && state.company,
          }}
        />

        {!loadingCredits && dataCredits.data && dataCredits.data.length === 0 && (
          <EmptyBlock
            {...(selectedCountry && 'freshInvestigation' in selectedCountry
              ? {
                  freshInvestigation: selectedCountry.freshInvestigation !== false,
                }
              : {})}
            description={creditReportPageVars.not_found_message}
            buttonTitle={creditReportPageVars.not_found_button}
            slug={secondaryNavigation.fresh_investigation.slug}
          />
        )}

        {loadingCredits && state.data.length === 0 && <InfinityLoader type="loader-border" />}

        {state.data.length > 0 && (
          <div className={styles['searching_table']} data-test="searching_table">
            <div className={styles['searching_table__results']}>
              {state.data.length} {creditReportPageVars.table_result_title}
            </div>

            <div className={styles['searching_table_description']}>
              <div className={styles['searching_table_description__name']}>
                {creditReportPageVars.first_table_column_title}
              </div>
              <div className={styles['searching_table_description__city']}>
                {creditReportPageVars.second_table_column_title}
              </div>
              <div className={styles['searching_table_description__address']}>
                {creditReportPageVars.third_table_column_title}
              </div>
              <div className={styles['searching_table_description__regno']}>
                {creditReportPageVars.four_table_column_title}
              </div>
              <div className={styles['searching_table_description__status']}>
                {creditReportPageVars.fifth_table_column_title}
              </div>
              <div className={styles['searching_table_description__action']}>
                {creditReportPageVars.six_table_column_title}
              </div>
            </div>

            {state.data.map((item) => (
              <Fragment key={item.id}>
                <div className={styles['searching_table_item_responsive']}>
                  <div className={styles['searching_table_item_responsive__name']}>
                    <p>{creditReportPageVars.first_table_column_title}</p>
                    <span className={styles['link']}>{item.name}</span>
                  </div>

                  <div className={styles['searching_table_item_responsive__city']}>
                    <p>{creditReportPageVars.second_table_column_title}</p>
                    <span>{item.address.city || '---'}</span>
                  </div>

                  <div className={styles['searching_table_item_responsive__address']}>
                    <p>{creditReportPageVars.third_table_column_title}</p>
                    <Icon type="credit-search-geolocation" />

                    <span>{item.address.street || '---'}</span>
                  </div>

                  <div className={styles['searching_table_item_responsive__regno']}>
                    <p>{creditReportPageVars.four_table_column_title}</p>
                    <span>{item.registration_number || '---'}</span>
                  </div>

                  <div className={styles['searching_table_item_responsive__status']}>
                    <p>{creditReportPageVars.fifth_table_column_title}</p>
                    <div
                      className={cn(
                        item.status !== null && styles['status-default'],
                        item.status !== null && styles[capitalize(item.status)],
                      )}
                    />
                    <div className={`circle-${item.status}`} />

                    <span
                      className={cn(item.status === null && styles['status-null'], styles[`status-${item.status}`])}
                    >
                      {item.status ? capitalize(item.status) : item.status === null ? '---' : 'Unactive'}
                    </span>
                  </div>

                  <div className={styles['searching_table_item_responsive__action']}>
                    <BuyReportButton type="primary" report={item} country={state.country}>
                      <span>{creditReportPageVars.button_report_placeholder}</span>
                    </BuyReportButton>
                  </div>
                </div>

                <div
                  className={styles['searching_table_item']}
                  data-test="searching-table-item"
                  key={item.registration_number}
                >
                  <div className={styles['searching_table_item__name']} data-test="searching_table_item__name">
                    <span className={styles['link']}>{item.name}</span>
                  </div>

                  <div className={styles['searching_table_item__city']}>{item.address.city || '---'}</div>

                  <div className={styles['searching_table_item__address']}>
                    {item.address.street && <Icon type="credit-search-geolocation" />}

                    <span>{item.address.street || '---'}</span>
                  </div>

                  <div className={styles['searching_table_item__regno']}>{item.registration_number || '---'}</div>

                  <div className={styles['searching_table_item__status']}>
                    <div
                      className={cn(
                        item.status !== null && styles['status-default'],
                        item.status !== null && styles[capitalize(item.status)],
                      )}
                    />

                    <div className={styles[`circle-${item.status}`]} />

                    <span
                      className={cn(item.status === null && styles['status-null'], styles[`status-${item.status}`])}
                    >
                      {item.status ? capitalize(item.status) : item.status === null ? '---' : 'Unactive'}
                    </span>
                  </div>

                  <div className={styles['searching_table_item__action']}>
                    <BuyReportButton report={item} country={state.country}>
                      {creditReportPageVars.button_report_placeholder}
                    </BuyReportButton>
                  </div>
                </div>
              </Fragment>
            ))}

            {state.data.length > 0 && loadingCredits && <InfinityLoader type="loader-bottom" />}
          </div>
        )}
      </div>
    </>
  );
};
