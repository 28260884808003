import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import cn from 'classnames';

import type { DataItem, HeadersItem } from 'libs/http/api/dashboard/dashboard.types';
import type { $Object } from 'libs/object/object.types';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';
import { Checkbox } from 'ui/atoms/Checkbox/Checkbox';
import { Button } from 'ui/atoms/Button/Button';
import { SelectLoader } from 'ui/atoms/Select/SelectLoader';
import { DynamicImport } from 'ui/DynamicImport';

import styles from './ConfigureColumns.module.scss';

interface Props {
  data: DataItem[];
  headers: HeadersItem[];
  config: $Object<any>;
  loading?: boolean;
  onChangeFirstRowHeaders: (newChecked: boolean) => void;
  onSelectColl: (i: number, value: string) => void;
  firstRowHeaders: boolean;
  modalRef?: RefObject<any> | undefined;
}

export const ConfigureColumns = ({
  data,
  headers,
  config,
  loading,
  onChangeFirstRowHeaders,
  firstRowHeaders,
  onSelectColl,
  modalRef,
}: Props) => {
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [shake, setShake] = useState<string | null>(null);

  useEffect(() => {
    if (timer.current !== undefined) {
      clearTimeout(timer.current);
    }
  }, []);

  const onChangeSelect = useCallback((idx: number, value: string) => onSelectColl(idx, value), []);

  const onShake = useCallback(
    (key: string) => (): void => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }

      setShake(key);

      timer.current = setTimeout(() => setShake(null), 500);
    },
    [],
  );

  const options = headers.map((item) => ({
    value: item.codename,
    title: item.name,
  }));

  const onFilterOptions = (input: any, option: any) =>
    option ? option.key.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;

  if (loading) {
    return <LoaderSpin />;
  }

  return (
    <>
      {onChangeFirstRowHeaders !== undefined && (
        <Checkbox
          checked={firstRowHeaders}
          onChange={onChangeFirstRowHeaders}
          label="The first row is for the headers"
          className={styles['configure-columns_headers']}
        />
      )}

      <div className={styles['configure-columns']}>
        <div className={styles['configure-columns_slider']}>
          {(Object.values(data[0]) as string[]).map((item, idx) => {
            const active = Boolean(config[idx]);

            const defaultValue: string | null | undefined = headers?.reduce(
              (acc: string | null, current: { codename: string; columns?: number[] }) => {
                if (acc === null && current.columns?.includes(idx)) {
                  acc = current.codename;
                }

                return acc;
              },
              null,
            );

            return (
              <div
                className={cn(styles['configure-columns_card'], active && styles['configure-columns_active'])}
                key={idx}
              >
                <DynamicImport fallback={() => <SelectLoader size="md" title="Select an option" />}>
                  {() => {
                    const Component = dynamic(() => import('ui/atoms/Select/Select'), {
                      ssr: false,
                      loading: () => <SelectLoader size="md" title="Select an option" />,
                    });

                    return (
                      <Component
                        options={options}
                        onChange={(value) => onChangeSelect(idx, value as string)}
                        value={config[idx]}
                        placeholder="Select an option"
                        filterOptions={onFilterOptions}
                        className={cn(
                          styles['configure-columns_select'],
                          !active && !defaultValue && shake === item && styles['shake'],
                        )}
                        {...(modalRef?.current !== undefined ? { getPopupContainer: () => modalRef?.current } : {})}
                      />
                    );
                  }}
                </DynamicImport>

                <div className={styles['configure-columns_context']}>
                  {firstRowHeaders === false && <span className={styles['configure-columns_title']}>{item}</span>}

                  {data.slice(1).map((row) => (
                    <span key={row[0]}>{row[idx] || '——'}</span>
                  ))}
                </div>

                <Button size={48} type={config[idx] ? 'primary' : 'invert'} onClick={onShake(item)}>
                  Enrich by this column
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
