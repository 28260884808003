/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useState, useCallback, type ReactNode } from 'react';

import { $Object } from 'libs/object/object.types';
import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { Price, PriceItem, Report } from 'libs/http/api/dashboard/dashboard.types';
import { stringifyQuery } from 'libs/node';

import { useRequest } from 'hooks/useRequest';

import { Button } from 'ui/atoms/Button/Button';
import type { ButtonType } from 'ui/atoms/Button/Button.types';

import styles from './BuyReportButton.module.scss';

interface Props {
  country: string;
  countryId?: number;
  report: Report;
  children: ReactNode;
  type?: ButtonType;
}

const priceOptions = {
  loading: false,
  data: {
    credit_reports: [],
    credit_reports_1: [],
    credit_reports_5: [],
    credit_reports_20: [],
    credit_reports_50: [],
  },
};

const GENERIC_ERROR = 'Not available';

const CREDIT_REPORT_PROMOTED = ['it', 'gb', 'sg', 'us'];

const baseClass = 'buy-report-button';

export const BuyReportButton = ({ country: $country, report, children, type = 'invert' }: Props) => {
  const { query } = useRouter();
  const { request, loading, errors } = useRequest<Price>(priceOptions);

  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (Object.keys(errors).length) {
      setMessage(GENERIC_ERROR);
    }
  }, [errors]);

  const onBuyReport = useCallback(async () => {
    setMessage(undefined);

    const country = $country.toLowerCase();
    const dataPrice = await request(dashboard.price({ country_code: country, lang: `${query.lang}` }));
    const hasPromotion = CREDIT_REPORT_PROMOTED.includes(country.toLowerCase());

    const order = {
      data: [] as $Object<any>,
      origin: location.href,
    };

    const priceKey = hasPromotion ? 'credit_reports_1' : 'credit_reports';
    const price =
      dataPrice[priceKey].find(({ codename }) => `credit_reports_${country}` === codename) || ({} as PriceItem) || {};

    if (!price.id) {
      setMessage(GENERIC_ERROR);
    }

    order.data.push({
      type: 'credit_report',
      data: {
        id: price.id,
        title: report.name,
        pay_one: true,
        source_id: encodeURIComponent(report.id),
        country,
      },
    });

    window.open(`${process.env.PLATFORM}/public/payment/summary?${stringifyQuery(order)}`, '_self');
  }, [$country, request, query.lang, report.name, report.id]);

  return (
    <Button
      size={32}
      loadingDelay={100}
      type={type}
      className={styles[baseClass]}
      loading={loading}
      onClick={onBuyReport}
    >
      {message || children}
    </Button>
  );
};
