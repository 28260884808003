import cn from 'classnames';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './SpecialOffer.module.scss';

interface OfferProps {
  type?: 'best' | 'free';
  icon?: string;
  value: string;
}

export const SpecialOffer = (props: OfferProps) => {
  return (
    <div className={cn(styles.wrapper, props.type && styles[props.type])}>
      {props.icon && <Icon type={props.icon} />}

      {props.value}
    </div>
  );
};
